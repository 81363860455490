<template>
  <div>
    <div class="responsive relative">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-table
        ref="refBasicTable"
        :items="gridData || []"
        :fields="columns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        no-local-sorting
        show-empty
        :tbody-tr-class="rowClass"
        @row-clicked="rowClick"
        :hover="isRowClicked"
      >
        <template #empty>
          <div class="text-center">
            Нет данных
          </div>
        </template>
        <template #row-details="row">
          <b-card
            v-if="row.fields[0] && row.fields[0].expand && row.fields[0].expand.tabs"
            no-body
            class="mb-0"
          >
            <b-tabs
              content
            >
              <b-tab
                v-for="(tab, key) in row.fields[0].expand.tabs"
                :key="key"
                :active="key === 0"
              >
                <template #title>
                  <span class="d-none d-sm-inline">{{ tab.title }}</span>
                </template>
                <inner-table
                  v-if="tab.content === 'table'"
                  :items="[]"
                  :columns="tab.columns"
                  :api="tab.api"
                  :api-id="row.item.id"
                  :hide-pagination="tab.hidePagination"
                />
                <div class="p-1">
                  <html-content
                    v-if="tab.content === 'html'"
                    :api="tab.api"
                    :api-id="row.item.id"
                  />
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
          <div
            v-if="row.fields[0] && row.fields[0].expand && row.fields[0].expand.div"
          >
            <div
              v-for="(div, key) in row.fields[0].expand.div"
              :key="key"
            >
              <html-content
                v-if="div.content === 'html'"
                :api="div.api"
                :api-id="row.item.id"
              />
            </div>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div>
            {{ data.item.created_at | moment('DD.MM.YYYY') }}
          </div>
        </template>
        <template #cell(visited_at)="data">
          <div>
            {{ data.item.visited_at | moment('DD.MM.YYYY') }}
          </div>
        </template>
        <template #cell(name)="data">
          <div v-if="data.field.detailsPage">
            <b-link
              v-if="data.field.to"
              :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
              class="font-weight-bold"
            >
              <span
                v-html="data.item.name"
              />
            </b-link>
            <b-link
              v-if="data.field.href"
              :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
              :target="data.field.target"
              class="font-weight-bold"
            >
              <span
                v-html="data.item.name"
              />
            </b-link>
          </div>
          <div v-else>
            <span
              v-html="data.item.name"
            />
          </div>
        </template>
        <template #cell(profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              v-if="data.item.profile"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.profile.avatar && `${server + data.item.profile.avatar}`"
                  :text="avatarText(data.item.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              v-if="data.item.profile"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.profile.avatar && `${server + data.item.profile.avatar}`"
                  :text="avatarText(data.item.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(color)="data">
          <div>
            <b-badge :variant="data.item.color">{{ colors[data.item.color] }}</b-badge>
          </div>
        </template>
        <template #cell(category)="data">
          <div v-if="data.item.category">
            <b-badge :variant="data.item.category.color">{{ data.item.category.name }}</b-badge>
          </div>
        </template>
        <template #cell(categories)="data">
          <div v-if="data.item.categories">
            <b-badge
              v-for="(category, key) in data.item.categories"
              :key="key"
              :variant="category.color"
              class="mr-50"
            >
              {{ category.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(profile.category)="data">
          <div v-if="data.item.profile.category">
            <b-badge :variant="data.item.profile.category.color">{{ data.item.profile.category.name }}</b-badge>
          </div>
        </template>
        <template #cell(subscription.subscription_type)="data">
          <div v-if="data.item.subscription.subscription_type">
            {{ data.item.subscription.hall.name }}: {{ data.item.subscription.subscription_type }}
          </div>
        </template>
        <template #cell(profile.dob)="data">
          <div>
            {{ data.item.profile.dob }}
          </div>
        </template>
        <template #cell(player.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.player.profile.avatar && `${server + data.item.player.profile.avatar}`"
                  :text="avatarText(data.item.player.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.player.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.player.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.player.profile.avatar && `${server + data.item.player.profile.avatar}`"
                  :text="avatarText(data.item.player.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.player.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(partner.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.partner.profile.avatar && `${server + data.item.partner.profile.avatar}`"
                  :text="avatarText(data.item.partner.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.partner.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.partner.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.partner.profile.avatar && `${server + data.item.partner.profile.avatar}`"
                  :text="avatarText(data.item.partner.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.partner.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(admin.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              v-if="data.item.admin"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.admin.profile.avatar && `${server + data.item.admin.profile.avatar}`"
                  :text="avatarText(data.item.admin.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.admin.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.admin.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              v-if="data.item.admin"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.admin.profile.avatar && `${server + data.item.admin.profile.avatar}`"
                  :text="avatarText(data.item.admin.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.admin.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(event.admin.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              v-if="data.item.event.admin"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.event.admin.profile.avatar && `${server + data.item.event.admin.profile.avatar}`"
                  :text="avatarText(data.item.event.admin.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.event.admin.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.event.admin.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              v-if="data.item.event.admin"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.event.admin.profile.avatar && `${server + data.item.event.admin.profile.avatar}`"
                  :text="avatarText(data.item.event.admin.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.event.admin.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(trainer.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              v-if="data.item.trainer"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.trainer.profile.avatar && `${server + data.item.trainer.profile.avatar}`"
                  :text="avatarText(data.item.trainer.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.trainer.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.trainer.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              v-if="data.item.trainer"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.trainer.profile.avatar && `${server + data.item.trainer.profile.avatar}`"
                  :text="avatarText(data.item.trainer.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.trainer.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(event.trainer.profile.fio)="data">
          <div v-if="data.field.detailsPage">
            <b-media
              v-if="data.item.trainer"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.event.trainer.profile.avatar && `${server + data.item.event.trainer.profile.avatar}`"
                  :text="avatarText(data.item.event.trainer.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <b-link
                v-if="data.field.to"
                :to="{ name: `${data.field.detailsPage}`, params: { id: id_key ? data.item[id_key] : data.item.id } }"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.event.trainer.profile.fio"
                />
              </b-link>
              <b-link
                v-if="data.field.href"
                :href="`${data.field.detailsPage}/${id_key ? data.item[id_key] : data.item.id}`"
                :target="data.field.target"
                class="font-weight-bold"
              >
                <span
                  v-html="data.item.event.trainer.profile.fio"
                />
              </b-link>
            </b-media>
          </div>
          <div v-else>
            <b-media
              v-if="data.item.event.trainer"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.event.trainer.profile.avatar && `${server + data.item.event.trainer.profile.avatar}`"
                  :text="avatarText(data.item.event.trainer.profile.fio.replace(/[0-9]/g, ''))"
                />
              </template>
              <span
                v-html="data.item.event.trainer.profile.fio"
              />
            </b-media>
          </div>
        </template>
        <template #cell(player.profile.category)="data">
          <div v-if="data.item.player.profile.category">
            <b-badge :variant="data.item.player.profile.category.color">{{ data.item.player.profile.category.name }}</b-badge>
          </div>
        </template>
        <template #cell(player.profile.dob)="data">
          <div>
            {{ data.item.player.profile.dob }}
          </div>
        </template>
        <template #cell(price)="data">
          <div>
            <span class="text-nowrap">{{ data.item.price }}&nbsp;₽</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.field.actions && data.field.actions.length"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div
              v-for="(action, key) in data.field.actions"
              :key="key"
            >
              <b-dropdown-item
                v-if="action.type === 'route'"
                :to="{ name: action.routeName, params: { id: data.item.id } }"
              >
                <feather-icon :icon="action.icon" />
                <span class="align-middle ml-50">{{ action.label }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="(!data.item.Actions || !action.name) || (data.item.Actions && data.item.Actions.indexOf(action.name) > -1)"
                @click="execAction({ ...action, apiId: data.item.id })"
              >
                <feather-icon :icon="action.icon" />
                <span class="align-middle ml-50">{{ action.label.replace('{id}', data.item.id) }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <b-row
      v-if="!hidePagination"
      class="mt-2"
    >

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BPagination, BOverlay,
  // BButton,
  VBTooltip, BTabs, BTab, BCard,
  BDropdown, BDropdownItem, BMedia, BAvatar,
  BLink, BBadge,
} from 'bootstrap-vue'
import HtmlContent from '@core/components/html-content/HtmlContent.vue'
import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import basicTableStoreModule from './basicTableStoreModule'
import store from '@/store'
import useBasicTable from './useBasicTable'
import InnerTable from './InnerTable.vue'

export default {
  name: 'BasicTable',
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    // BButton,
    BTabs,
    BTab,
    BCard,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    InnerTable,
    HtmlContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: false,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    params: {
      type: Object,
      required: false,
    },
    queryParams: {
      type: Array,
      required: false,
    },
    brancheId: {
      type: [Object, String, Number, null],
      required: false,
    },
    userId: {
      type: [Object, String, Number, null],
      required: false,
    },
    categoryId: {
      type: [Object, String, Number, null],
      required: false,
    },
    hallId: {
      type: [Object, String, Number, null],
      required: false,
    },
    id_buyer: {
      type: [Object, String, null],
      required: false,
    },
    id_key: {
      type: [String, null],
      required: false,
    },
    q: {
      type: [Object, String, null],
      required: false,
    },
    low_training: {
      type: [Boolean, null],
      required: false,
      default: undefined,
    },
    sleeping: {
      type: [Boolean, null],
      required: false,
      default: undefined,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    isRowClicked: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'basic-table'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, basicTableStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isBefore = visitedAt => moment(visitedAt).isBefore(moment().subtract(14, 'days'))
    const rowClass = item => {
      if (item) {
        if (item.visited && isBefore(item.visited.visited_at)) {
          return 'table-warning'
        }

        if (item.subscription && item.subscription.left <= 3) {
          return 'table-danger'
        }
      }

      return ''
    }

    const {
      refBasicTable,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,
      sortBy,
      isSortDirDesc,

      fetchData,
      rowClicked,
      rowClick,
      execAction,
    } = useBasicTable(props, emit)

    if (props.api && (props.autoload || props.loadByTab === null)) {
      fetchData()
    }

    const colors = {
      primary: 'Синий',
      success: 'Зеленый',
      danger: 'Красный',
      warning: 'Желтый',
      secondary: 'Серый',
    }

    const server = process.env.VUE_APP_SERVER

    return {
      server,
      refBasicTable,
      gridData,
      loading,
      total,
      currentPage,
      perPage,
      perPageOptions,
      dataMeta,
      sortBy,
      isSortDirDesc,

      rowClass,
      rowClicked,
      execAction,
      rowClick,

      colors,
      avatarText,
    }
  },
}
</script>
<style scoped>
  .responsive {
    width: 100%;
    overflow: auto;
  }
  .rotate {
    transform: rotate3d(0,0,1,90deg);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .orders-table >>> .b-table-details td:first-child {
    padding-top: 0;
  }
  .orders-table >>> .b-table-empty-row td:first-child {
    padding-top: 0.72rem;
  }
</style>
