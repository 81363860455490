import axios from '@axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line
    async fetchData(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api

      return axios.get(url, {
        params: payload.params,
      })
    },
    /* eslint-disable */
    async execAction(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api

      if (payload.alert) {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: `${payload.label.replace('{id}', payload.apiId)}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            const body = payload.body || {}
            if (!payload.method) {
              return axios.get(url, {
                params: payload.params,
              }).catch(err => {
                const { data } = err.response

                // eslint-disable-next-line no-underscore-dangle
                return _vm.$swal({
                  icon: 'error',
                  html: data,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => false)
              })
            }

            if (payload.method === 'post') {
              return axios.post(url, {
                ...body,
                params: payload.params,
              })
            }

            if (payload.method === 'put') {
              return axios.put(url, {
                ...body,
                params: payload.params,
              })
            }
            if (payload.method === 'delete') {
              return axios.delete(url)
            }
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { message } = result.value.data
            return this._vm.$swal({
              icon: 'success',
              text: message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => true)
          }
        })
      } else if (payload.name === 'Comment') {
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          text: payload.label.replace('{id}', payload.apiId),
          icon: 'question',
          input: 'textarea',
          inputPlaceholder: 'Текст сообщения',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            input: 'mt-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm(message) {
            return axios.post(url, {
              xparam1: message,
              xparam2: 1,
            })
          },
        }).then(result => {
          if (result.isConfirmed) {
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'error',
                  text: data,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => false)
              } else {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'success',
                  text: data,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => true)
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => false)
            }
          }
        })
      } else if (payload.name === 'Status') {
        const res = await axios.get('https://api.bc-sport.ru/api/v1/json/cp/service/data/list/invStatusSet')
        // eslint-disable-next-line
        const { data, success } = res.data
        let options = ''
        if (success && data && data.length > 0) {
          data.forEach(item => {
            options += `<option value="${item.value}">${item.text}</option>`
          })
        }
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          icon: 'question',
          html: `<div id="swal2-content" class="swal2-html-container" style="display: block;">${payload.label.replace('{id}', payload.apiId)}?</div><select id="select-status" class="custom-select custom-select-lg mt-1">${options}</select>`
          + '<textarea id="select-msg" placeholder="Текст сообщения" rows="3" wrap="soft" class="swal2-textarea mt-1"></textarea>',
          showCancelButton: true,
          confirmButtonText: 'Отправить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            const status = document.getElementById('select-status').value
            const msg = document.getElementById('select-msg').value
            return axios.put(url, {
              xparam1: msg,
              xparam2: status,
            })
          },
        }).then(result => {
          if (result.isConfirmed) {
            // eslint-disable-next-line
            const { data, success } = result.value.data
            if (data) {
              if (!success) {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'error',
                  text: data,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => false)
              } else {
                // eslint-disable-next-line no-underscore-dangle
                return this._vm.$swal({
                  icon: 'success',
                  text: data,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => true)
              }
            } else {
              // eslint-disable-next-line no-underscore-dangle
              return this._vm.$swal({
                icon: 'error',
                text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => false)
            }
          }

          return false
        })
      } else if (payload.name === 'payment') {
        const subscriptions = await axios.get('http://api.bc-sport.ru/api/subscriptions', {
          params: { branche_id: router.currentRoute.params.branche_id },
        })
        const receiptTypes = await axios.get('http://api.bc-sport.ru/api/receipttypes', {
          params: { branche_id: router.currentRoute.params.branche_id },
        })
        const admin = await axios.get('http://api.bc-sport.ru/api/admins', {
          params: { branche_id: router.currentRoute.params.branche_id },
        })

        let options = ''
        if (subscriptions.data.data && subscriptions.data.data.length > 0) {
          subscriptions.data.data.forEach(item => {
            options += `<option value="${item.id}">${item.hall.name}: ${item.subscription_type}</option>`
          })
        }

        let options2 = ''
        if (receiptTypes.data.data && receiptTypes.data.data.length > 0) {
          receiptTypes.data.data.forEach(item2 => {
            options2 += `<option value="${item2.id}">${item2.name}</option>`
          })
        }

        let options3 = ''
        if (admin.data.data && admin.data.data.length > 0) {
          admin.data.data.forEach(item3 => {
            options3 += `<option value="${item3.id}">${item3.profile.fio}</option>`
          })
        }
        // eslint-disable-next-line no-underscore-dangle
        return this._vm.$swal({
          icon: '',
          html: `<div id="swal2-content" class="swal2-html-container" style="display: block;"><h2>Добавить платеж</h2></div>
          <select id="select-subscription" class="custom-select custom-select-lg mt-1">${options}</select>
          <select id="select-receipttype" class="custom-select custom-select-lg mt-1">${options2}</select>
          <select id="select-admins" class="custom-select custom-select-lg mt-1">${options3}</select>
          `,
          showCancelButton: true,
          confirmButtonText: 'Добавить',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            const subscription = document.getElementById('select-subscription').value
            const receipttype = document.getElementById('select-receipttype').value
            const admin = document.getElementById('select-admins').value

            return axios.post('http://api.bc-sport.ru/api/finances', {
              subscription_id: subscription,
              receipt_type_id: receipttype,
              user_id: payload.apiId,
              admin_id: admin,
              branche_id: router.currentRoute.params.branche_id,
            })
          },
        }).then(result => {
          if (result.isConfirmed) {
            if (result.value.data) {
              return this._vm.$swal({
                icon: 'success',
                text: 'Платеж добавлен',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => true)
            }
          }
        })
      } else {
        return axios.get(url, {
          params: payload.params,
        })
      }
    },
  },
}
