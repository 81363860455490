<template>
  <b-sidebar
    id="create-update-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-overlay
            :show="$store.state.app.loadingCreate"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />

          <!-- Last name -->
          <validation-provider
            v-for="(field, key) in _fields"
            :key="key"
            #default="validationContext"
            :name="field.label"
            :rules="field.rules"
          >
            <b-form-group
              :label="field.label"
              :label-for="field.name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                v-if="(field.type === 'text' || field.type === 'number' || field.type === 'email')"
                :id="field.name"
                v-model="field.value"
                :placeholder="field.placeholder"
                :type="field.type"
                :step="field.step"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-input
                v-if="(field.type === 'phone')"
                :id="field.name"
                v-model="field.value"
                v-mask="'+7(###) ###-####'"
                placeholder="+7(123) 456-7890"
                type="text"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-textarea
                v-if="field.type === 'textarea'"
                :id="field.name"
                v-model="field.value"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-input
                v-if="field.type === 'date'"
                :id="field.name"
                v-model="field.value"
                v-mask="'##.##.####'"
                placeholder="дд.мм.гггг"
                type="text"
                trim
              />

              <v-select
                v-if="field.type === 'v-select'"
                :id="field.name"
                :options="field.options"
                v-model="field.value"
                :get-option-label="option => (option.subscription_type ? `${option.hall.name}: ${option.subscription_type}` : '') || option.name || option.label || (option.profile && option.profile.fio) || option"
                :reduce="field.reduce ? item => item[field.reduce] || item : undefined"
                :multiple="field.multiple"
                :clearable="false"
                @search="searchData(field, $event)"
              >
                <span slot="no-options">
                  Нет данных
                </span>
              </v-select>

              <b-form-invalid-feedback
                v-if="validationContext.errors"
                style="display: block;"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton, BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { nextTick, watch, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import moment from 'moment'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    api: {
      type: String,
      required: true,
    },
    queryParams: {
      type: Array,
      required: false,
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    const { route } = useRouter()

    // eslint-disable-next-line
    const _fields = ref(props.fields)
    const sendData = ref({})
    const queryParams = ref({})

    const resetForm = () => {
      sendData.value = {}
      queryParams.value = {}
      _fields.value = props.fields
      refFormObserver.value.reset()
    }

    watch(() => props.isSidebarActive, () => {
      if (props.isSidebarActive) {
        if (props.queryParams && props.queryParams.length) {
          props.queryParams.forEach(p => {
            queryParams.value[p] = route.value.params[p]
          })
        }
        // eslint-disable-next-line
        _fields.value.forEach(field => {
          if (field.type === 'v-select') {
            if (field.api) {
              store.dispatch('app/fetchData', {
                api: field.api,
                params: {
                  ...queryParams.value,
                },
              }).then(res => {
                // eslint-disable-next-line
                field.options = res.data.data
              })
            }
          }
        })
      } else {
        resetForm()
      }
    })

    const searchData = debounce((field, val) => {
      queryParams.value = {}

      if (props.queryParams && props.queryParams.length) {
        props.queryParams.forEach(p => {
          queryParams.value[p] = route.value.params[p]
        })
      }

      store.dispatch('app/fetchData', {
        api: field.api,
        params: {
          ...queryParams.value,
          q: val,
        },
      }).then(res => {
        // eslint-disable-next-line
        field.options = res.data.data
      })
    }, 500)

    const onSubmit = () => {
      // eslint-disable-next-line
      _fields.value.forEach(field => {
        sendData.value[field.name] = field.value
      })

      sendData.value = {
        ...sendData.value,
        ...queryParams.value,
      }

      store.dispatch('app/sendData', {
        api: props.api,
        data: sendData.value,
      }).then(() => {
        emit('update:isSidebarActive', false)
        emit('update:refresh', true)
        nextTick(() => {
          // reset refresh
          emit('update:refresh', false)
        })

        sendData.value = {}
        queryParams.value = {}
        _fields.value = props.fields
      })
    }

    const inputDate = (field, val) => {
      // eslint-disable-next-line
      field.value = moment(val).format('DD.MM.YYYY')
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      _fields,
      onSubmit,
      inputDate,
      searchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#create-update-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
