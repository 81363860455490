<template>
  <b-button
    :variant="variant"
    :size="size"
    :class="classes"
    @click="() => {
      $emit('update:sidebar', true)
    }"
  >
    <span class="align-middle">{{ label }}</span>
  </b-button>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>
