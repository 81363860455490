<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <div v-html="html" />
  </div>
</template>
<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import htmlContentStoreModule from './htmlContentStoreModule'
import store from '@/store'

export default {
  components: {
    BOverlay,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      required: false,
      default: null,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'html-content'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, htmlContentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router } = useRouter()

    const id = router.currentRoute.params.id || props.apiId
    const params = Object.keys(router.currentRoute.query).length !== 0 ? router.currentRoute.query : undefined

    const html = ref('')
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      store.dispatch('html-content/fetchData', {
        api: props.api,
        apiId: id,
        params,
      })
        .then(response => {
          const { data } = response
          html.value = data.success ? data.data : data
          loading.value = false
        })
        .catch(() => {
          html.value = ''
          loading.value = false
        })
    }

    if (props.api) {
      fetchData()
    }

    return {
      loading,
      html,
    }
  },
}
</script>
